<template>
  <div>
    {{ content }}
  </div>
</template>

<script>
import shave from 'shave';
import ResizeObserver from 'resize-observer-polyfill';

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resizeObserver: null,
    };
  },
  mounted() {
    this.limit();
    this.initResizeObserver();
  },
  watch: {
    content() {
      this.reset();
      this.$nextTick(() => {
        this.limit();
      });
    },
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(() => {
        this.reset();
        this.limit();
      });
      this.resizeObserver.observe(this.$el.parentElement);
    },
    reset() {
      this.$el.innerText = this.content;
    },
    limit() {
      if (this.$el.parentElement.clientHeight > 0
        && this.$el.parentElement.clientHeight < this.$el.clientHeight) {
        shave(this.$el, this.$el.parentElement.clientHeight);
      }
    },
  },
};
</script>
