<template>
  <div class="smp-downloadButton">
    <button
      :class="buttonClasses"
      @click.prevent="download">
      <i class="mr2"><fa-icon :icon="['fas', 'download']" /></i>
      {{ $t('posting.downloadCta') }}
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { backendService } from '@/services/BackendService';
import logger from '@/util/logger';

export default {
  props: {
    buttonClasses: {
      type: String,
      required: false,
      default: 'smp-button smp-button-primary',
    },
    posting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['currentLocale']),
    ...mapGetters(['defaultLocale']),
  },
  methods: {
    async download() {
      try {
        const url = await backendService.downloadNearLiveContent(
          this.posting.type === 'nearlive'
            ? { postingId: this.posting.id }
            : {
              objectKey: this.posting.s3.key,
              id: this.posting.id,
            },
        );

        if (url) {
          window.location.href = url;
        }
      } catch (e) {
        logger.captureException(e);

        let message = e?.data?.message ?? 'generic_error';

        const messageKey = `generic.serverMessages.${message}`;
        if (this.$t(messageKey) !== messageKey) {
          message = this.$t(messageKey);
        }

        this.$notification.error(message);
      }
    },
  },
};
</script>
