<template>
  <header class="smp-baseHeader">
    <div class="smp-baseHeader-row1">
      <div class="smp-container--large flex items-center justify-between">
        <div class="smp-baseHeader-logoWrapper">
          <div class="smp-baseHeader-logoContainer" />
        </div>

        <div class="smp-baseHeader-actions">
          <locale-menu v-if="showLocaleMenu" />
          <user-menu class="ml2" />
        </div>
      </div>
    </div>

    <div class="smp-baseHeader-row2">
      <div class="smp-container--large flex items-center">
        <slot />
      </div>
    </div>

    <tab-bar />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import LocaleMenu from '@/components/LocaleMenu.vue';
import UserMenu from '@/components/UserMenu.vue';
import TabBar from '@/components/TabBar.vue';
import { logout } from '@/util/firebase';

export default {
  components: {
    LocaleMenu,
    UserMenu,
    TabBar,
  },
  computed: {
    ...mapGetters(['showLocaleMenu']),
  },
  methods: {
    async logout() {
      await logout();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-baseHeader {
  .flex-none;
  padding: @space-3 0;
  background: @color-mono-100;
  border-bottom: 2px solid @color-mono-70;

  .smp-baseHeader-row1 {
    .smp-baseHeader-actions {
      display: flex;
    }
  }

  .smp-baseHeader-row2:not(:empty) {
    margin-top: @space-3 + @space-2;
    margin-bottom: 35px;
  }
}
</style>
