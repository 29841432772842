<template>
  <div class="smp-nearLivePage-gridView">
    <div
      class="smp-nearLivePage-grid"
      v-if="postings && postings.length > 0">
      <near-live-posting
        v-for="posting in postings"
        :key="posting.id"
        :posting="posting"
        :match="match" />
    </div>
    <div
      v-else
      class="flex justify-center mt6 ms3">
      {{ $t('nearLive.noResults') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NearLivePosting from '@/components/near-live/NearLivePosting.vue';

export default {
  components: { NearLivePosting },
  computed: {
    ...mapState('nearLive', ['postings', 'match']),
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-nearLivePage-gridView {
  .flex-auto;
  overflow-y: auto;
  padding: @space-3 @space-2 @space-6;

  .smp-nearLivePage-grid {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
