<template>
  <div
    class="smp-nearLivePosting">
    <div class="smp-card">
      <div
        v-if="previewImage"
        class="smp-nearLivePosting-imageContainer"
        @click.prevent="openPreview">
        <img :src="previewImage">

        <div
          class="smp-nearLivePosting-imageContainer-blurredBackground"
          :style="{'background-image': `url(${previewImage})`}" />
      </div>
      <div class="smp-nearLivePosting-content">
        <div
          class="smp-nearLivePosting-team ms-1"
          v-if="posting.team">
          <team
            :code="posting.team"
            :show-flag="false" />
        </div>
        <div
          class="smp-nearLivePosting-comment"
          v-if="posting.comment"
          @click.prevent="openPreview">
          <text-limit :content="posting.comment" />
        </div>
        <div class="smp-nearLivePosting-date ms-1 bold">
          <tooltip
            :content="getPublishedDate(true, true, false)"
            :delay="{ show: 500, hide: 0 }">
            {{ publishedNotice }}
          </tooltip>
        </div>

        <div class="smp-nearLivePosting-footer">
          <div
            class="smp-nearLivePosting-creatorTime text-muted ms-1 flex-auto"
            v-if="!userIsInCreatorTimezone">
            <strong>{{ creatorTimezoneLabel }}</strong><br>
            {{ getPublishedDate(false, true, true) }}
          </div>

          <div class="smp-nearLivePosting-downloadCta flex-none">
            <download-button
              :posting="posting"
              button-classes="smp-button smp-button-light" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="viewMode === 'timeline'"
      class="smp-nearLivePosting-timelineMarker">
      <span class="line">
        {{ capturedTime }}
      </span>
      <span class="dot" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from '@/util/moment';
import DownloadButton from '@/components/near-live/NearLiveDownloadButton.vue';
import Team from '@/components/Team.vue';
import Tooltip from '@/components/Tooltip.vue';
import TextLimit from '@/components/TextLimit.vue';

export default {
  components: {
    DownloadButton, Team, Tooltip, TextLimit,
  },
  props: {
    posting: {
      type: Object,
      required: true,
    },
    match: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState(['currentLocale', 'creatorTimezone']),
    ...mapState('user', ['userTimezone']),
    ...mapState('nearLive', ['viewMode']),
    ...mapGetters(['creatorTimezoneLabel']),
    captured() {
      return this.posting.captured || this.posting.created;
    },
    userIsInCreatorTimezone() {
      return this.userTimezone === this.creatorTimezone;
    },
    capturedTime() {
      let time = moment(this.captured, moment.getDateTimeFormat());
      time = time.toUserTimezone();

      return time.format('HH:mm');
    },
    publishedNotice() {
      let m = moment(this.captured, moment.getDateTimeFormat());
      m = m.toUserTimezone();

      if (this.currentLocale) m.locale(this.currentLocale);

      return m.fromNow();
    },
    previewImage() {
      return this.posting.previewImage ?? null;
    },
    teaser() {
      const { comment } = this.posting;

      if (comment && typeof comment === 'string') {
        return comment.length > 100
          ? `${comment.substr(0, 100)}...`
          : comment;
      }

      return null;
    },
  },
  methods: {
    ...mapActions('nearLive', ['showPreview']),
    openPreview() {
      this.showPreview(this.posting);
    },
    getPublishedDate(useUserTimezone = true, format = true, short = false) {
      let dateObj = moment(this.captured, moment.getDateTimeFormat());

      if (useUserTimezone === true) dateObj = dateObj.toUserTimezone();
      else dateObj = dateObj.toCreatorTimezone();

      if (!format) return dateObj;

      return dateObj.format(moment.getUserDateTimeFormat(short));
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-nearLivePosting {
  display: flex;
  padding: @space-2;
  .flex-none;
  width: 100%;

  @media screen and (min-width: @breakpoint-xs) {
    width: 100% / 2;
  }

  @media screen and (min-width: @breakpoint-sm) {
    width: 100% / 3;
  }

  @media screen and (min-width: @breakpoint-lg) {
    width: 100% / 4;
  }

  @media screen and (min-width: @breakpoint-xl) {
    width: 100% / 5;
  }

  @media screen and (min-width: @containerLarge) {
    width: 100% / 6;
  }

  .smp-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    .smp-nearLivePosting-imageContainer {
      overflow: hidden;
      cursor: pointer;
      position: relative;
      .flex-none;
      border-top-left-radius: @defaultBorderRadius;
      border-top-right-radius: @defaultBorderRadius;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;

      img {
        position: absolute;
        z-index: 10;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .smp-nearLivePosting-imageContainer-blurredBackground {
        position: absolute;
        top: -10px;
        left: -10px;
        width: ~'calc(100% + 20px)';
        height: ~'calc(100% + 20px)';
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        .blur(3px);
        opacity: 0.6;
        z-index: 1;
      }
    }

    .smp-nearLivePosting-content {
      padding: @space-3;
      display: flex;
      flex-direction: column;
      .flex-auto;

      .smp-nearLivePosting-comment {
        height: 50px;
        .flex-auto;
        cursor: pointer;
        .ms(1);
        margin: @space-1 0;
        .font-normal;
        .font-highlight;
      }
    }

    .smp-nearLivePosting-footer {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-top: @space-2;
    }
  }
}
</style>
