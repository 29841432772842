<template>
  <transition name="fade">
    <div
      class="smp-postingPreview smp-overlay">
      <div
        class="smp-postingPreview--loading"
        v-if="loading">
        <fa-icon
          :icon="['fas', 'spinner']"
          spin />
      </div>

      <div
        class="smp-postingPreview-noClose"
        v-if="!loading && !closing">
        <!-- Prev/Next navigation buttons -->
        <div
          class="smp-postingPreview-navigation smp-postingPreview-prev"
          :class="{disabled: !prevPost || updating}"
          v-if="navigationVisible">
          <button
            class="smp-button"
            @click.prevent="goToPrevPost">
            <fa-icon :icon="['fas', 'angle-left']" />
          </button>
        </div>
        <div
          class="smp-postingPreview-navigation smp-postingPreview-next"
          :class="{disabled: !nextPost || updating}"
          v-if="navigationVisible">
          <button
            class="smp-button"
            @click.prevent="goToNextPost">
            <fa-icon :icon="['fas', 'angle-right']" />
          </button>
        </div>

        <!-- Loader shown during content/posting update -->
        <div
          class="smp-postingPreview--updating"
          v-if="showUpdateLoader">
          <fa-icon
            :icon="['fas', 'spinner']"
            spin />
        </div>
      </div>

      <transition name="zoom">
        <div
          class="smp-postingPreview-wrapper smp-postingPreview-noClose"
          :class="{updating: showUpdateLoader}"
          v-show="!loading && !closing">
          <!-- Close btn-->
          <div
            class="smp-postingPreview-closeBtn"
            @click="close()">
            <fa-icon :icon="['fas', 'times']" />
          </div>

          <div class="smp-postingPreview-content smp-overlay-boxShadow">
            <div class="smp-postingPreview-media flex-auto">
              <video
                :key="`${activePosting.id}`"
                width="1280"
                controls
                controlsList="nodownload"
                ref="player">
                <source
                  :src="signedClipUrl"
                  type="video/mp4">
                {{ $t('posting.preview.html5VideoNotSupported') }}
              </video>
            </div>

            <div class="smp-postingPreview-data flex-none pb3">
              <div class="smp-postingPreview-dataWrapper flex flex-wrap">
                <div
                  class="smp-dataField col-12"
                  v-if="activePosting.comment">
                  <div class="smp-dataField-label">
                    {{ $t('nearLive.preview.headlines.comment') }}
                  </div>
                  <div class="smp-dataField-value font-normal font-highlight">
                    {{ activePosting.comment }}
                  </div>
                </div>

                <div class="smp-dataField col-12 xs-col-6">
                  <div class="smp-dataField-label">
                    {{ $t('posting.preview.headlines.userTimezone') }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ capturedDateInUserTimezone }}
                  </div>
                </div>

                <div class="smp-dataField col-12 xs-col-6">
                  <div class="smp-dataField-label">
                    {{ creatorTimezoneLabel }}
                  </div>
                  <div class="smp-dataField-value">
                    {{ capturedDateInCreatorTimezone }}
                  </div>
                </div>

                <div class="smp-dataField col-12">
                  <div class="smp-dataField-label">
                    {{ $t(`generic.contestants.${contestantType}.singular`) }}
                  </div>
                  <div class="smp-dataField-value">
                    <team :code="activePosting.team" />
                  </div>
                </div>
              </div>

              <div class="smp-postingPreview-downloadActionWrapper flex justify-center mt4">
                <download-button
                  :posting="activePosting"
                  button-classes="smp-button smp-button-primary smp-button--large" />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Overlay from '@/mixins/Overlay';
import logger from '@/util/logger';
import LogService from '@/services/LogService';
import Team from '@/components/Team.vue';
import DownloadButton from '@/components/near-live/NearLiveDownloadButton.vue';
import { getTeamLabel } from '@/util/i18n';
import moment from '@/util/moment';

let updateLoaderTimeout;

export default {
  components: { Team, DownloadButton },
  data() {
    return {
      containerClass: 'smp-postingPreview-noClose',
      loading: true,
      signedClipUrl: null,
      destroyed: false,
      closing: false,
      updating: false,
      showUpdateLoader: false,
    };
  },
  computed: {
    ...mapState(['teamList', 'currentLocale']),
    ...mapState('nearLive', ['activePosting', 'match', 'postings']),
    ...mapGetters(['creatorTimezoneLabel', 'contestantType']),
    currentPostingIndex() {
      if (!this.postings) return -1;
      return this.postings.findIndex((p) => p.id === this.activePosting.id);
    },
    nextPost() {
      if (this.currentPostingIndex === -1) return null;
      const nextPost = this.postings[this.currentPostingIndex + 1];

      return typeof nextPost !== 'undefined' ? nextPost : null;
    },
    prevPost() {
      if (this.currentPostingIndex === -1) return null;
      const prevPost = this.postings[this.currentPostingIndex - 1];

      return typeof prevPost !== 'undefined' ? prevPost : null;
    },
    navigationVisible() {
      return this.nextPost || this.prevPost;
    },
    teamAName() {
      return getTeamLabel(this.match.teamA, this.teamList, this.currentLocale);
    },
    teamBName() {
      return getTeamLabel(this.match.teamB, this.teamList, this.currentLocale);
    },
    kickoffDate() {
      return moment(this.match.kickoff);
    },
    capturedDate() {
      return moment(this.activePosting.captured);
    },
    capturedDateInUserTimezone() {
      return this.capturedDate.toUserTimezone().format(moment.getUserDateTimeFormat());
    },
    capturedDateInCreatorTimezone() {
      return this.capturedDate.toCreatorTimezone().format(moment.getUserDateTimeFormat());
    },
    matchInfo() {
      return `${this.teamAName} : ${this.teamBName}, ${this.kickoffDate.toUserTimezone().format(moment.getUserDateTimeFormat())}`;
    },
  },
  mixins: [Overlay],
  methods: {
    ...mapActions('nearLive', ['closePreview', 'getSignedUrl', 'setActivePosting']),
    async init() {
      this.$nextTick(() => {
        if (this.$refs.player) this.$refs.player.load();
      });

      this.signedClipUrl = await this.getSignedUrl(
        this.activePosting.type === 'nearlive'
          ? { postingId: this.activePosting.id }
          : {
            objectKey: this.activePosting.s3.key,
            id: this.activePosting.id,
          },
      );

      this.$nextTick(() => {
        const { player } = this.$refs;
        if (player) {
          const canPlayHandler = () => {
            this.loaded();
            this.startVideo();
          };

          player.addEventListener('canplay', canPlayHandler, false);
          player.load();

          const logTmp = { category: 'near-live', id: this.activePosting.id };
          // Log player events: https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
          ['play', 'pause', 'ended', 'seeked', 'waiting'].forEach((event) => {
            player.addEventListener(event, () => LogService.event('Player', event, logTmp.category, logTmp.id));
          });

          // Explicitly throw error for sending to Sentry
          player.addEventListener('error', (e) => {
            logger.captureException(e);
          });
        }
      });
    },
    loaded() {
      this.loading = false;
      this.updating = false;
    },
    startVideo() {
      // Break if the canplay callback fires after the component was already destroyed
      // Can happen on fast clicks on the overlay while the video is still loading
      if (this.destroyed) { return; }

      // Get the player and start video
      const { player } = this.$refs;

      // Check if the player was found, break otherwise.
      // Happens when the method is called after the user has already navigated to the another post
      if (!player) return;

      let promise = player.play();

      // Check if a promise was returned and catch it
      if (promise !== undefined) {
        promise.catch(() => {
          // Mute the player and try again
          player.muted = true;
          promise = player.play();

          if (promise !== undefined) {
            promise.catch(() => {
              LogService.event('Player', 'Autoplay prevented', 'near-live', this.activePosting.id);
            });
          }
        });
      }
    },
    stopVideo() {
      // Get the player and start video
      const { player } = this.$refs;

      if (player) {
        player.pause();
      }
    },
    handleKeyup(e) {
      if (e.keyCode === 27) this.close();
      if (e.keyCode === 37 && this.prevPost) this.goToPrevPost();
      if (e.keyCode === 39 && this.nextPost) this.goToNextPost();
    },
    close() {
      this.closing = true;
      setTimeout(() => {
        this.closePreview();
      }, 100);
    },
    goToPrevPost() {
      this.goToPost(this.prevPost);
    },
    goToNextPost() {
      this.goToPost(this.nextPost);
    },
    goToPost(post) {
      if (!this.navigationVisible) return;
      this.updating = true;
      this.stopVideo();
      this.setActivePosting(post);
    },
  },
  beforeDestroy() {
    // Set destroyed flag to prevent the player from starting as soon as the canplay listener fires
    this.destroyed = true;

    // Additionally remove the listener if the player could be fetched
    const { player } = this.$refs;
    if (player) { player.removeEventListener('canplay', this.startVideo); }
  },
  watch: {
    activePosting() {
      this.init();
    },
    updating(value) {
      if (value) {
        this.signedClipUrl = null;
        updateLoaderTimeout = setTimeout(() => {
          this.showUpdateLoader = true;
        }, 400);
      } else {
        this.showUpdateLoader = false;
        clearTimeout(updateLoaderTimeout);

        if (this.isClip && this.$refs.player) {
          this.$refs.player.load();
        }
      }
    },
  },
  created() {
    this.init();
  },
};
</script>
